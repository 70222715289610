#TUTUTUPAYMENTGATEWAYMODAL .modal-header{
    height: auto;
    display: block;
    padding:0;
}

.modal-content-tututu-payment-gateway{
    max-width: 410px;
    max-height: 92vh;
    overflow: auto;
    overflow-x: hidden;
}

/* Styling Scrollbar */
.modal-content-tututu-payment-gateway::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 0px;
	background-color: #F5F5F5;
}

.modal-content-tututu-payment-gateway::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.modal-content-tututu-payment-gateway::-webkit-scrollbar-thumb
{
	border-radius: 0px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #1f295c;
}
/* End Of Styling Scrollbar */

.modal-content-tututu-payment-gateway .modal-title{
    display: block;
    text-align: center;
    width: 100%;
}
.modal-content-tututu-payment-gateway .modal-title img{
    /* margin-top: -17px; */
    padding: 18px;
    width: 61%;
}
.modal-content-tututu-payment-gateway .modal-body{
    background: #f3f3f3;
}
.select-payment-channel{
    display: table;
    width: 100%;
    background: white;
}
.select-payment-channel .payment-channel-type{
    /* 
    max-width: 400px;
    max-height: 92vh;
    overflow: scroll;
    overflow-x: hidden; 
    */
}
.select-payment-channel .payment-channel-type .page-header{
    border: 1px solid #e3e3e3;
    display: table;
    width: 100%;
    padding: 10px 20px;
    border-bottom: 0;
    cursor: pointer;
    border-left: 5px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    transition: 0.3s;
}
.select-payment-channel .payment-channel-type .page-header:hover{
    background: #f7f7f7;
    color: #000;
    border-left: 5px solid #4287ba;
}
.select-payment-channel .payment-channel-type .page-header.active{
    background: #f7f7f7;
    color: #000;
    border-left: 5px solid #4287ba;
}
.select-payment-channel .payment-channel-type .page-header .left{
    float: left;
    font-size: 35px;
    width: 50px;
    text-align: center;
    display: none;
}
.select-payment-channel .payment-channel-type .page-header .right{
    float: left;
    margin-left: 0px;
}
.select-payment-channel .payment-channel-type .page-header .right strong{
    font-size: 12px;
}
.select-payment-channel .payment-channel-type .page-header .right h1{
    font-size: 15px;
    margin: 0;
}
.select-payment-channel .payment-channel-type .channel{
    display: none;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #e3e3e3;
    border-left: 1px solid #e3e3e3;
    border-right: 1px solid #e3e3e3;
    cursor: pointer;
}
.select-payment-channel .payment-channel-type .channel:hover{
    background: #f7f7f7!important;
}
.select-payment-channel .payment-channel-type .channel:focus{
    background: #e3e3e3 !important;
}
.select-payment-channel .payment-channel-type .channel:active{
    background: #e3e3e3 !important;
}
.select-payment-channel .payment-channel-type .channel.active{
    display: table;
    width: 100%;
    background: #ffffff;
    color: #000;
    border-bottom: 1px solid #ffffff;
    transition: 0.3s;
}
.select-payment-channel .payment-channel-type .channel .left{
    float: left;
}
.select-payment-channel .payment-channel-type .channel .left img{
    width: 60px;
    margin-left: 10px;
    margin-top: 10px;
}
.select-payment-channel .payment-channel-type .channel .right{
    float: left;
    margin-left: 20px;
    display: block;
}
.select-payment-channel .payment-channel-type .channel .right strong{
    display: block;
    font-size: 12px;
}
.select-payment-channel .payment-channel-type .channel .right p{
    margin: 0;
    padding: 0;
    font-size: 12px;
}
.tututu-payment-gateway-footer-copyright p{
    text-align: center;
    font-size: 11px;
    margin: 0;
    color: #f57500;
}

.paymeny-summary{
    padding: 10px;
    background: white;
    border: 1px solid #e3e3e3;
    margin-bottom: 20px;
    border-radius: 0.2em;
}
.paymeny-summary .summary-price{
    display: table;
    width: 100%;
    border-bottom: none;
    margin-bottom: 0;
}
.paymeny-summary .summary-price .left{
    float: left;
}
.paymeny-summary .summary-price .left strong{
    display: block;
    margin-top: 11px;
}
.paymeny-summary .summary-price .right{
    float: right;
}
.paymeny-summary .summary-price .right{
    
}
.paymeny-summary .summary-price .right strong{
    font-size: 25px;
}
.paymeny-summary .summary-price .right strong .currency{
    font-size: 12px;
}
.paymeny-summary .summary-number{
    display: table;
    width: 100%;
}
.paymeny-summary .summary-number .left{
    float: left;
}
.paymeny-summary .summary-number .left strong{
    font-size: 12px;
}
.paymeny-summary .summary-number .right{
    float: right;
}
.paymeny-summary .summary-number .right strong{
    font-size: 12px;
}
.tututu-payment-gateway-make-secure-payment{
    padding: 20px;
    text-align: center;
    background: white;
    border: 1px solid #e3e3e3;
    border-radius: 0.3em;
}
.tututu-payment-gateway-make-secure-payment .area-loading img{
    width: 50px;
}
.tututu-payment-gateway-make-secure-payment h4{
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 0;
}

.tututu-payment-next-step{
    padding: 20px;
    text-align: center;
    background: white;
    border: 1px solid #e3e3e3;
    border-radius: 0.3em;
}
.tututu-payment-next-step .next-step{
    font-size: 20px;
    font-family: "Roboto",sans-serif;
    border-bottom: 1px solid #e3e3e3;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-weight: 600;
}
.tututu-payment-next-step .direct-method{
    background: #eaeaea;
    padding: 10px 0 2px 0;
    border-radius: 0.3em;
    margin-bottom: 10px;
    margin-top: 10px; 
}
.tututu-payment-next-step .direct-method h2{
    font-size: 15px;
    font-family: "Roboto",sans-serif;
}
.tututu-payment-next-step .direct-method strong{
    font-size: 25px;
    color: red;
}
.tututu-payment-next-step .info{
    text-align: center;
    display: block;
    font-size: 12px;
    color: black;
}
.tututu-payment-next-step .info ol{
    /* padding: 0; */
    text-align: left;
    padding-left: 15px;
}
.tututu-payment-next-step .button-action-bottom{
    margin-top: 10px;
    border-top: 1px solid #e3e3e3;
    padding-top: 10px;
}
.tututu-payment-next-step .button-action-bottom a.link_winpay{
    background: #2196F3;
    color: #fff;
    width: 100%;
    display: block;
    padding: 10px;
    border-radius: .3em;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: 0.2s;
}
.tututu-payment-next-step .button-action-bottom a.link_winpay:hover{
    background: #1b7ece;
    color: #fff;
    text-decoration: none;
}
.tututu-payment-next-step .button-action-bottom a.link_close{
    background: #fff;
    color: #6f6f6f;
    width: 100%;
    display: block;
    padding: 6px 10px 4px 10px;
    border-radius: .3em;
    margin-top: 15px;
    border: 1px solid #bdbdbd;
    text-transform: uppercase;
    transition: 0.2s;
}
.tututu-payment-next-step .button-action-bottom a.link_close:hover{
    text-decoration: none;
    border: 1px solid #888888;
    color: #444444;
}
.payment-with-paypal{
    text-align: center;
    background: white;
    padding: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 0.3em;
    border: 1px solid #e3e3e3;
}
.payment-with-paypal .page-header{
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e3e3e3;
}
.payment-with-paypal .page-header h1{
    font-size: 18px;
    font-family: "Roboto",sans-serif;
}

.back-step{
    float: left;
    color: #213963;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    margin-top: 26px;
    margin-left: 20px;
    position: absolute;
    opacity: .7;
}
.back-step:hover{
    color: #213963;
    opacity: .8;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #3a3a3a;
    text-shadow: 0 1px 0 #fff;
    opacity: .8;
    margin: 0 !important;
    margin-top: -65px !important;
    padding: 14px !important;
    margin-right: 7px !important;
}

.bank-tf {
    font-size: 15px;
    cursor: default;
}
b.price_thanks {
    font-size: 32px;
    font-weight: bold;
    font-family: "Roboto", open-sans;
}
span.price_last {
    background: #2196F3;
    color: #fff;
    padding: 0 5px;
    border-radius: 7px;
    /* margin-left: -5px; */
}
.price_important_info {
    display: inline-block;
    padding: .5em 1em;
    font-size: 13px;
    border-radius: 5px;
    background-color: #FFC107;
    margin-bottom: 20px;
}
.price_important_info:before {
    content: "";
    position: absolute;
    margin: auto;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #FFC107;
    margin-top: -12px;
    margin-left: 185px;
}
.info-bankss {
    height: fit-content;
    border-top: 1px dashed #d4d4d4;
    padding: 10px 0 5px 0;
    display: flex;
}
.bank-icon {
    width: 30%;
    height: 70px;
    float: left;
}
.bank-icon img {
    max-width: 80px;
    margin-top: 14px;
}
.desc-bankss {
    width: 70%;
    height: 70px;
    float: left;
    text-align: left;
    font-size: 15px;
    padding-left: 10px;
}
.bank-name {
    font-weight: bold;
    font-size: 14px;
}
.bank-tf {
    font-size: 15px;
}
.bank-number {
    font-size: 15px;
}