body {
  background: #f2f5f7;
  font-family: "Myriad Pro", Myriad, "Liberation Sans", "Nimbus Sans L", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
.padink {
  padding: 10px 90px;
}
.bgputih {
background: #fff;
}
span.notif {
font-size: 12px;
line-height: 30px;
margin-left: 2px;
font-style: italic;
color: #4e4e4e;
}
table.prd-tbl tr td {
    padding: 0;
    margin: 0;
}
.required_text{
  color: red;
}
.product-not-found{
  background: white;
  padding: 10em;
  border-radius: 0.5em;
  text-align: center;
  display:none;
}
.prd-tbl {
    border: 1px solid #ddd;
    border-radius: 15px;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    cursor: default;
    padding: 25px 10px;
    box-shadow: 0px 5px 9px 0px #0000001c;
}
.prd-name {
    font-size: 23px;
    font-weight: bold;
    margin-top: 20px;
}
.prd-desc {
    font-style: italic;
    color: #5f5f5f;
    font-size: 14px;
    margin-top: 8px;
}
.prd-cost {
    font-weight: bold;
    font-size: 22px;
    margin-top: 15px;
    background: #faffd0;
    display: inline-block;
    padding: 0 10px;
}
hr.separate {
    width: 65%;
    margin-bottom: 25px;
    border: 1px dashed #eaeaea;
    margin-top: 5px;
}
ul.breadcrumb {
  padding: 10px 16px 10px 0;
  list-style: none;
  background: #ffffff !important;
}

/* Display list items side by side */
ul.breadcrumb li {
  display: inline;
  font-size: 13px;
}

/* Add a slash symbol (/) before/behind each list item */
ul.breadcrumb li+li:before {
  padding: 8px;
  color: black;
  content: "/\00a0";
}

/* Add a color to all links inside the list */
ul.breadcrumb li a {
  color: #0275d8;
  text-decoration: none;
}

/* Add a color on mouse-over */
ul.breadcrumb li a:hover {
  color: #01447e;
  text-decoration: underline;
}
.breadcustom {
    margin-top: 20px;
    margin-bottom: -30px;
}
.form-group label {
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
}
.secure-left i {
    font-size: 33px;
    margin-left: 15px;	
}
.secure-left {
    float: left;
}
.secure-right {
    font-size: 12px;
    float: left;
    margin-left: 15px;
}
.header-web {
    height: 85px;
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #ddd;
}
.header-container {
    width: 1100px;
    margin: 0 auto;
}
.logosss svg {
  height: 84px;
  width: 100px;
  margin-top: 0;
}
.logosss {
  float: left;
  padding: 0px 0 22px;
}
.menusss {
    float: right;
}
.menusss ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.menusss ul li {
    float: left;
}
.menusss ul li a {
    color: #242424;
    font-family: "Raleway", sans-serif;
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
}
.menusss ul li {
    float: left;
    padding: 30px 15px 10px 15px;
}
.menusss ul li a:hover {
    text-decoration: none;
    color: #aa2323;
}
.bgshadow {
    border-left: 1px solid #ddd;
    padding-left: 0;
    padding-right: 0;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}
.footer-pay {
    border-top: 1px solid #e8e8e8;
    margin-top: 50px;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 15px;
}
.footer-pay p {
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    line-height: 12px;
    font-weight: 500;
    color: #6b6b6b;
}
.headink h2 {
    font-size: 22px;
    font-weight: bold;
    font-family: "Quicksand", sans-serif;
}

.tab-selector-payment{
    /* max-width: 864px !important; */
    display: block;
    margin: 0 auto;
    /* padding-bottom: 57px; */
}

.tab-selector-payment .payment-area{
    border-radius: 0.4em;
    margin-top: 15px;
    /* margin-bottom: 30px; */
    border: 1px solid #e1e1e1;
    padding: 0;
    margin-left: -10px;
    margin-right: -10px;
}

.tab-selector-payment .payment-area .header-payment-area{
    display: table;
    width: 100%;
    padding: 20px;
    background: #fbfbfb;
    padding: 25px;
    /* margin-bottom: 20px; */
    cursor:pointer;
    border-radius: 0.4em;
}

.tab-selector-payment .payment-area .header-payment-area i{
    float: left;
    font-size: 27px;
}

.tab-selector-payment .payment-area .header-payment-area h3{
    float: left;
    font-size: 15px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 0px;
    padding: 0;
    margin-top: 3px;
    font-weight: bold;
}

.tab-selector-payment .payment-area .header-payment-area img{
    float: left;
    height: 30px;
    margin-top: -2px;
}

.tab-selector-payment .content-payment-area{

}

.tab-selector-payment .content-payment-area .frame-secure-label{
    max-width: 527px;
    margin: 0 auto;
    margin-bottom: 10px;
    padding: 0;
    margin-top: 20px;
}

.tab-selector-payment .content-payment-area .payment-bank{

}

.tab-selector-payment .content-payment-area .table-list-product-payment{
    width: 500px;
    margin: 0 auto;
}

.tab-selector-payment .content-payment-area .table-list-product-payment tr{

}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td{
    padding-bottom: 5px;
}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.col-separator{

}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.col-separator hr{

}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.red{
    color: #d41111;
}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.green{
    color: #0fa114;
}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.col1{
    font-size: 15px;
}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.col2{

}

.tab-selector-payment .content-payment-area .table-list-product-payment tr td.col3{
    text-align: right;
}

.tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon{
    margin-top: 20px;
    width: 292px;
    border-radius: 0.4em;
    border: 1px solid #ccc;
    padding: 3px;
}

.tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon input{
    border: none;
    padding: 5px;
    text-align: left;
    font-size: 13px;
    height: 32px;
    width: 215px;
}

.tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon a{
    background: #0e1b23;
    color: white;
    font-size: 13px;
}

.tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon a:hover{

}

.tab-selector-payment .content-payment-area .next-payment{
    padding-top: 20px;
    padding-bottom: 20px;
    /* padding: 20px; */
    width: 500px;
    margin: 0 auto;
    display: table;
}

.tab-selector-payment .content-payment-area .next-payment .btn_place_my_order{
    float: right;
}

.coming-soon{
    padding: 20px 10px;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    color: #4a4a4a;
}

.discount_notif{
    position: absolute;
    color: white;
    padding: 8px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    margin-left: -4px;
    margin-top: 1px;
    font-size: 11px;
    width: 290px;
    text-align: center;
}

.discount_notif.red{
    background: #d41111;
}

.discount_notif.green{
    background: #0fa114;
}
.page-header h2{
  text-align: center;
  padding-bottom: 20px;
  font-weight: bold;
  margin-top: 0;
  font-size: 26px;
  padding-top: 30px;
}

.page-header h3{
  text-align: center;
  padding-bottom: 20px;
  font-weight: 500;
  padding-top: 0px;
  font-size: 16px;
  margin-top: -10px;
}

.FrameThanks{
    background: white;
    padding: 10px;
    text-align: center;
    border-radius: 0.5em;
    padding-top: 20px;
    padding-bottom: 0;
}

.FrameThanks .thanks_caption{
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
}

.FrameThanks .caption_1{
    
}

.FrameThanks .price_thanks{
    display: block;
    font-size: 40px;
}
.FrameThanks .price_thanks .price_front{
    color: #212529;
}
.FrameThanks .price_thanks .price_last{
    color: #fff;
}

.FrameThanks .price_important_info::before{
    content: "";
    position: absolute;
    margin: auto;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #FFC107;
    margin-top: -12px;
    margin-left: 208px;
}

.FrameThanks .price_important_info{
    display: inline-block;
    padding: .5em 1em;
    font-size: .9em;
    border-radius: 5px;
    background-color: #FFC107;
    margin-bottom: 20px;
}

.FrameThanks .caption_2{
    font-weight: bold;
    font-size: 17px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.FrameThanks .payment_acount_detil{
    border: 2px dashed #bdbdbd;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
    display: table;
}

.FrameThanks .payment_acount_detil .child-bank{
    float: left;
    display: table;
    height: 200px;
    width: 50%;
}

.FrameThanks .payment_acount_detil img{
  width: 139px;
  margin-bottom: 10px;
}

.FrameThanks .payment_acount_detil h3{
  font-weight: 700;
  font-size: 15px;
  margin-top: 10px;
}

.FrameThanks .payment_acount_detil h4{
    font-size: 12px;
}

.FrameThanks .payment_acount_detil h4{
    font-size: 12px;
}

.FrameThanks .caption_3 p{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    line-height: 30px;
    margin-bottom: 20px;
}
.frame-coupon input {
    outline: 0;
    text-transform: uppercase;
    font-weight: bold;
    text-indent: 5px;
}
.menusss_mobile_trigger{
    display: none;
}
.wrap-input100{
    z-index: auto !important;
}
#pswd_info {
    position: absolute;
    bottom: -155px;
    bottom: -115px\9;
    right: 145px;
    width: 210px;
    padding: 2px;
    background: #fefefe;
    font-size: .875em;
    border-radius: 5px;
    box-shadow: 0 1px 3px #ccc;
    border: 1px solid #ddd;
    z-index: 1000;
    padding-top: 15px;
    display: none;
}
#pswd_info h4 {
    margin: 0 0 10px 0;
    padding: 0;
    font-weight: normal;
    font-size: 15px;
    text-align: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
}
#pswd_info::before {
    content: "\25B2";
    position:absolute;
    top:-12px;
    left:45%;
    font-size:14px;
    line-height:14px;
    color:#ddd;
    text-shadow:none;
    display:block;
}
#pswd_info ul{
    list-style: none;
    padding: 0;
}
#pswd_info ul li{
    font-size: 13px;
}
.FrameListProducts .child{
  background: white;
  border-radius: 0.5em;
  padding-top: 30px;
  padding-bottom: 30px;
  max-width: 283px;
  margin-right: 0px;
}

.FrameListProducts .child:last-child{
  margin-right: 0;
}

.FrameListProducts .child img{
  width:100%;
}

.FrameListProducts .child h3{
  text-align: center;
  font-size: 21px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.FrameListProducts .child p{
  font-size: 14px;
  text-align: center;
  display: block;
  height: 60px;
}

.FrameListProducts .child a{
  margin-top:20px;
  font-family: "Lato",Sans-serif;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: .6px;
  background-color: #5285b7;
  border-radius: 50px 50px 50px 50px;
  padding: 15px 30px 15px 30px;
  color: white;
  width: 100%;
  display: inherit;
  text-align: center;
}

.FrameListProducts .child a.buy{
  background-color: #ea6527;
}

.FrameListProducts .child a.disabled{
  background: gray;
}

.FrameListProducts .child a:hover{
  text-decoration: none;
  background-color: #0c3b69;
}

.FrameListProducts .child a.disabled:hover{
  background: gray;
  cursor: default;
}

.pop_member_choose_products{
  text-align: center;
}

.pop_member_choose_products h3{
  font-size: 20px;
  margin-bottom: 25px;
}

.pop_member_choose_products .harga_before_discount{
  color: red;
  text-decoration: line-through;
}

.pop_member_choose_products .harga_after_discount{
  font-weight: bold;
  margin-bottom: 25px;
}

.pop_member_choose_products a{
  margin-bottom: 20px;
}

.pop_member_choose_products a:hover{
  
}

.FrameListProducts {
  width: fit-content;
  margin: 0 auto;
}

.icon-success{
  text-align: center;
  margin-top: 80px;
  font-size: 90px;
  color: green;
}
.separator-list-license-wa-sender{
  border: 1px solid #e5e5e5;
  padding: 10px;
  border-radius: 0.4em;
  margin-bottom: 20px;
}
span.origin-price {
  color: red;
  font-weight: bold;
  text-decoration: line-through;
  font-size: 18px;
  margin-right: 5px;
}
.tab-selector-payment .payment-area .header-payment-area:hover {
  background: #f7f7f7;
}

.img-not-found{
  text-align: center;
  padding: 50px;
  width: 60%;
  margin: 0 auto;
}
.img-not-found img{
  width:100%;
}

.img-waiting{
  text-align: center;
  padding: 20px;
  width: 60%;
  margin: 0 auto;
}
.img-waiting img{
  width:64px;
  height:64px;
}


.tutor-container {
  width: 90%;
  margin: 0 auto;
  min-height: 300px;
  margin-top: 20px;
}
.box-yt {
  width: 45%;
  min-height: 200px;
  box-shadow: 0px 6px 9px 0px #00000030;
  transition: 0.3s;
  float: left;
  margin-left: 30px;
}
.if-yt {
  width: fit-content;
  background: #f2f2f2;
  min-height: fit-content;
}
.title-yt {
  font-size: 17px;
  font-weight: bold;
  padding: 15px 10px 15px 10px;
}
a.link-yt {
  text-decoration: none;
  color: #2d2d2d;
  display: contents;
}
a.link-yt .box-yt:hover {
  box-shadow: 0px 7px 9px 0px #0000008a;
}
a.link-yt:hover {
  color: #88002e;
}

.bonus-wa button {
margin-right: 10px;
margin-bottom: 10px;
}

.list-menu-affiliate{
  display: table;
  width: 100%;
  margin-top: 20px;
}

.list-menu-affiliate ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  margin: 0 auto;
  padding: 0;
}

.list-menu-affiliate ul li{
  float: left;
  padding: 5px;
  padding-left: 25px;
  padding-right: 25px;
}

.list-menu-affiliate ul li a{
  color: #007bff;
  font-weight: bold;
}

.list-menu-affiliate ul li a.active{
  color: #362529;
}

.list-menu-affiliate ul li a:hover{
  color: #362529;
  text-decoration:none;
}

.form-regis-affiliate{
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.form-regis-affiliate .siklus-pembayaran{
  margin-bottom:20px;
}

.form-regis-affiliate .siklus-pembayaran h3{
  font-size: 18px;
  font-weight: bold;
  margin-top: 50px; 
}

.form-regis-affiliate .siklus-pembayaran p{
  font-size: 13px;
  margin-bottom: 10px;
}

.frame-affiliate-link{
  display: table;
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
}

.frame-affiliate-link .child-frame{
  float: left;
  width: 249px;
  margin-right: 26px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 1em;
}

.frame-affiliate-link .child-frame:last-child{
  margin-right: 0;
}

.frame-affiliate-link .child-frame .heading{
  position: absolute;
  background: #297bff;
  color: white;
  padding: 5px;
  border-radius: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -25px;
  margin-left: 10px;
}

.frame-affiliate-link .child-frame input{
  margin-top: 15px;
  font-size: 12px;
  font-weight: bold;
}


.frame-affiliate-shortsummary{
  display: table;
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
}

.frame-affiliate-shortsummary .child-frame{
  float: left;
  width: 192px;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 1em;
}


.frame-affiliate-shortsummary .child-frame:last-child{
  margin-right: 0;
}

.frame-affiliate-shortsummary .child-frame .heading{
  position: absolute;
  background: #297bff;
  color: white;
  padding: 5px;
  border-radius: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -25px;
  margin-left: 10px;
}

.frame-affiliate-shortsummary .child-frame .subheading{
  text-align: center;
  font-weight: bold;
  margin-top: 18px;
  font-size: 26px;
  margin-bottom: 7px;
}

.frame-affiliate-shortsummary .child-frame .subsubheading{
  text-align: center;
}

.chart-affiliate-area{
  width: 800px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 100px;
  display: table;
}

#reportrange{
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 300px;
  float: right;
  font-size: 13px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.pull-right{
  float: right;
  margin-bottom: 15px;
}

.pull-left{
  float: right;
  margin-bottom: 15px;
}

.frame-add-edit-kupon-affiliate{
  width: 800px;
  margin: 0 auto;
}

.diskon-price{

}

.diskon-price .child:first-child{
  border-bottom-left-radius: 0.5em;
  border-top-left-radius: 0.5em;
}

.diskon-price .child:last-child{
  border-bottom-right-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.diskon-price .child{
  width: 80px;
  text-align: center;
  float: left;
  border: 1px solid #ddd;
  padding: 5px;
  /* color: #212530; */
  cursor: pointer;
}

.diskon-price .child:hover{
  border: 1px solid #000;
}

.diskon-price .child.active{
  border: 1px solid #000;
}

.swtich_model_checkbox{
  height: 0;
width: 0;
visibility: hidden;
}

.label_swtich_model_checkbox{
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 29px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.label_swtich_model_checkbox:after{
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}


.swtich_model_checkbox:checked + .label_swtich_model_checkbox {
  background: #57b03b;
}

.swtich_model_checkbox:checked + .label_swtich_model_checkbox:after {
left: calc(100% - 5px);
transform: translateX(-100%);
}

.label_swtich_model_checkbox:active:after {
width: 50px;
}

.frame-table-leads-affiliate{
  width:800px;
  margin:0 auto;
}

.area-potensi-penghasilan{

}

.area-potensi-penghasilan .title-potensi-penghasilan{
  font-size: 14px;
}

.area-potensi-penghasilan .value-potensi-penghasilan{
  background: #51ae3c;
  color: white;
  padding: 10px;
  display: inline-block;
  border-radius: 0.5em;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 15px;
  font-weight: 600;
}

.frame-affiliate-shortsummary-orders{
  display: table;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.frame-affiliate-shortsummary-orders .child-frame{
  float: left;
  width: 207px;
  margin-right: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 1em;
}

.frame-affiliate-shortsummary-orders .child-frame:last-child{
  margin-right: 0;
}

.frame-affiliate-shortsummary-orders .child-frame .heading{
  position: absolute;
  background: #297bff;
  color: white;
  padding: 5px;
  border-radius: 0.5em;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: -25px;
  margin-left: 10px;
}

.frame-affiliate-shortsummary-orders .child-frame .subheading{
  text-align: center;
  font-weight: bold;
  margin-top: 18px;
  font-size: 26px;
  margin-bottom: 7px;
}

.frame-affiliate-shortsummary-orders .child-frame .subsubheading{
  text-align: center;
}

.frame-affiliate-shortsummary-payout{
  width:800px;
  margin: 0 auto;
}

.frame-breadcustom{
  margin-left: -160px;
}

.not-interested{
  font-size: 13px;
  color: #546479;
  font-style: italic;
  float: left;
  margin-top: 13px;
  font-weight: bold;
}

.goto-finish{
  font-size: 13px;
  color: #546479;
  font-style: italic;
  float: right;
  margin-top: 13px;
  font-weight: bold;
}

.top-header-bar{
  background: white;
  margin-bottom: 20px;
  box-shadow: 0px 0px 7px -3px #ccc;
}
  
.fill-content{
  width: 1140px;
    margin: 0 auto;
    padding: 10px;
  display: table;
}
.left-content{
  float: left;
    width: 212px;
}
.right-content{
  float: right;
  margin-top: 23px;
}
.tanya-admin{
      float: left;
    font-family: "Roboto",sans-serif!important;
    font-weight: 700;
    font-size: 20px;
}
.pulse{
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #a1c45a;
    margin-top: 11px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(161,196,90,.9);
    animation: pulse 1.5s infinite;
    float: left;
    margin-left: 20px;

    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}

.concs{
  float: left;
}

.concs img{
  height: auto;
    max-width: 100%;
    border: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    width: 24px;
    margin: 4px 0 0 20px;
}

.area-buy{
	display: table;
    width: 100%;
}
.buy-left-area{
	float: left;
    width: 68%;
    margin-right: 2%;
}
.buy-right-area{
  float: right;
  width: 30%;
}

.cont-area{
    background: white;
    padding: 20px;
    border-radius: 0.2em;
    box-shadow: 1px 2px 3px 0 #6161612b;
    margin-bottom: 20px;
}

.cont-header h2{
	font-size: 22px;
    font-family: "Roboto",sans-serif!important;
    font-weight: 600;
    color: #333333;
}

.cont-form{
  margin-top: 20px;
}

.child-cont-form{
  width: 100%;
  display: table;
  margin-top: 15px;
}
.child-cont-form .control-label{
font-weight: 700;
  font-size: 16px;
}
.child-cont-form .sub-child-cont-form{
  width: 48%;
  float: left;
  margin-right: 4%;
}
.child-cont-form .sub-child-cont-form:last-child{
  margin-right: 0%;
}

.child-list-product-buy{
  display: table;
  width: 100%;
  background: #f9f9f9;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 10px;
}

.child-list-product-buy:last-child{
  margin-bottom: 0px;
}

.thumbnail{
  float: left;
  width: 150px;
  margin-right: 20px;
}

.thumbnail img {
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.title-and-price{
    float: left;
    width: 50%;
    margin-top: 20px;
}
.title-and-price h3{
    font-size: 16px;
    color: #333333;
    font-weight: 600;
}
.title-and-price h4{
  font-size: 17px;
  color: #008205;
  font-weight: bold;
}
.action-button{
	display: table;
    float: right;
}
.action-button .frame-trash{
	display: table;
    width: 100%;
    margin-bottom: 10px;
}
.action-button .frame-trash .moveToTrash{
    float: right;
    padding: 7px;
    padding-top: 2px;
    padding-bottom: 2px;
}



.frame-increase-decrease{
  display: table;
  width: 100%;
}

.btn-decrease{
  background: #f8f9fa;
  border: none;
  font-weight: bold;
  color: #a2a1a1cc;
  border: 1px solid #ccc;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  outline: 0 !important;
float: left;
}

.input_qty{
  text-align: center;
  padding: 0;
  border: 1px solid #ccc;
  padding: 0;
  color: #333;
  width: 39px;
  font-size: 14px;
  height: 30px;
  outline: 0px;
  position: initial;
float: left;
}

.btn-increase {
  background: #f8f9fa;
  border: none;
  font-weight: bold;
  color: #a2a1a1cc;
  border: 1px solid #ccc;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  padding: 2px;
  padding-left: 10px;
  padding-right: 10px;
  outline: 0 !important;
float: left;
}

.grid-product{
  display: table;
  width: 100%;
  overflow: auto;
  width: max-content;
}

.grid-product::-webkit-scrollbar {
  width: 12px;
}

.grid-product::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}

.grid-product::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}

.grid-product .promo-percentage{
  font-size: 14px;
  background: #22ae1b;
  color: white;
  text-align: center;
  margin: 10px;
}
.grid-product .child {
  float: left;
  width: 119px;
  background: #fff;
  margin-right: 15px;
  margin-bottom: 15px;
  box-shadow: 1px 2px 13px 0 rgba(0,0,0,.1);
  z-index: 99;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  cursor: pointer;
}
.grid-product .child img{
  width: auto;
  text-align: center;
  height: 122px;
  margin: 0 auto;
  display: block;
}
.grid-product .child button{
	font-size: 12px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
}
.grid-product .child h3{
	font-size: 12px;
    text-align: center;
    display: block;
    margin: 0 auto;
    margin-top: 10px;
    height: 26px;
    margin-bottom: 10px;
}
.grid-product .child h3 {
  height: 26px;
  font-weight: bold;
}
.grid-product .child h3:hover {
  text-decoration: underline;
}
.grid-product .child h4{
    font-size: 14px;
    text-align: center;
    margin-bottom: 5px;
    font-weight: bold;
    color: #ff0000;
    text-decoration: line-through;
}
.grid-product .child strong{
    font-size: 14px;
    /* text-align: center; */
    margin-bottom: 10px;
    font-weight: bold;
    color: #333333;
    /* text-decoration: line-through; */
    text-align: center;
    display: block;
}

.payment-selector{
  margin-bottom: 10px;
  border-radius: .5em;
  border: 2px solid #ccc;
  padding: 10px;
  display: table;
  width: 100%;
  cursor: pointer;
}

.payment-selector.active, .payment-selector:hover {
  border: 2px solid #007bff;
}

.payment-selector.disabled{
  cursor: default;
}

.payment-selector.disabled:hover{
  background: #fff;
}

.payment-selector .left{
    float: left;
    width: 30%;
}

.payment-selector .left img {
  width: 80%;
  display: block;
  margin: 2px auto 0;
  background-color: white;
}

.payment-selector .center{
    float: left;
    width: 60%;
}

.payment-selector .center h2{
  font-size: 16px;
  color: #333;
  font-weight: 600;
  margin-bottom: -3px;
  margin-top: 5px;
}

.payment-selector .center small {
  color: #9f9f9f;
  font-style: italic;
  font-size: 12px;
}

.payment-selector .right{
    float: right;
    width: 10%;
}

.radio_payment_Selector{
	margin: 0 auto;
    display: block;
    margin-top: 15px;
}

.sub-header-cont-form{
	margin-bottom: 20px;
}
.sub-header-cont-form h4{
    font-size: 14px;
    font-weight: 700;
    border-bottom: 1px solid #ccc;
    padding-bottom: 3px;
    display: inline;
}
.child-summary-cont-form{
  display: table;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}
.child-summary-cont-form .left{
	float: left;
}
.child-summary-cont-form .left h3{
  font-size: 12px;
  color: #212529;
  font-weight: 600;
  word-break: break-all;
  -webkit-line-clamp: N;
  width: 173px;
}
.child-summary-cont-form .right{
	float: right;
}
.child-summary-cont-form .right h4{
    font-size: 12px;
    color: #c44b35;
    text-decoration: line-through;
    margin-bottom: 0px;
    font-weight: 600;
}
.child-summary-cont-form .right strong{
    font-size: 12px;
    text-align: right;
    display: block;
    margin-bottom: 10px;
}
.summary-area .child-summary{
	display: table;
    width: 100%;
}
.summary-area .child-summary strong.left{
	font-size: 14px;
    color: #212529;
    display: block;
    float: left;
}
.summary-area .child-summary strong.right{
	  display: block;
    float: right;
    font-size: 14px;
    color: #212529;
}
.summary-area .child-summary.diskon strong{
	color: #138e0d !important;
}

.grandtotal-area{
  margin-top: 10px;
  display: table;
  width: 100%;
  border-top: 1px solid #eaeaea;
  padding-top: 10px;
  margin-top: 20px;
}
.grandtotal-area .left{
	float: left;
    font-size: 16px;
}
.grandtotal-area .right{
    float: right;
    font-size: 16px;
}

.header-kode-kupon{
	margin-top: 30px;
}
.header-kode-kupon h3{
	font-size: 13px;
    font-weight: 600;
}
.frame-summary-right{
	padding-top: 0px;
    background: #fcfcfe;
    padding: 10px;
    box-shadow: 0px 0px 1px 0px #ccc;
    border-radius: 0.2em;
}

.submit-area-checkout input{
  width: 100%;
}

.header-finish h1{
  text-align: center;
  font-size: 27px;
}
.header-finish img {
  width: 230px;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
}

.table-invoice{
  background: #f2f5f7;
  text-align: left;
}

.parent-kode-kupon{
	display: table;
    width: 100%;
}
.parent-kode-kupon .form-control{
    width: 70%;
  float: left;
  font-size: 14px;
  text-transform: uppercase;
}
.parent-kode-kupon .btnApplyCoupon{
    float: right;
    width: 25%;
    font-size: 14px;
}

.right-secure-img{
  float: right;
  margin-top: -40px;
}
.right-secure-img img{
  width: 91px;
}

.frame-grid-product{
  width: auto;
  overflow: auto;
}

.grid-product .child h3 a{
  color: #333333;
  text-decoration: none;
}

.custom-field .form-control{
  width: 200px;
}


.app-footer-mobile{
  padding: 4em;
  text-align: center;
}

.app-footer-mobile .footer-title{
  color: #9a9d9e;
  font-style: italic;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: .5em;
}

.app-footer-mobile .footer-title img{
  width: 140px;
  position: relative;
  top: 0px;
  /* display: block; */
  /* margin: 0 auto; */
  margin-left: 10px;
}

.footer-copyright{
color: #babbbb;
  font-size: 0.8em;
}

.btn_process_checkout{
  display: block;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
}

.box-bantuan-shopping-cart {
  background: white;
  border-radius: 0.3em;
  margin-right: 0pc;
  margin-bottom: 15px;
  margin-top: 20px;
  border-top: 1px solid #ced4da;
  padding-top: 20px;
}

.box-bantuan-shopping-cart .help-cart{
  width: 100%;
  display: table;
}

.box-bantuan-shopping-cart .help-cart .left-info {
  float: left;
  width: 20%;
}

.box-bantuan-shopping-cart .help-cart .left-info img{
  margin: 0 auto;
  display: block;
}

.box-bantuan-shopping-cart .help-cart .right-info {
  float: right;
  width: 77%;
  color: #1f2a57;
}

.box-bantuan-shopping-cart .help-cart .right-info h1 {
  font-size: 20px;
  color: #ffa82b;
}

.box-bantuan-shopping-cart .help-cart .right-info p {
  font-size: 14px;
  margin-bottom: 0px;
  color: #333333;
}

.box-bantuan-shopping-cart .help-cart .right-info p a{
  color: #4387ba;
}

/* Menu Style Start */
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 31px;
  height: 24px;
  right: 13px;
  top: 17px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffa82b;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #e38b0c;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  right: 0 !important;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  padding-left: 0px;
  padding-right: 0px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  padding: 0;
}

.bm-item-list a{
  display: block;
  color: white;
  text-align: center;
  font-size: 17px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #575a64;
}

.bm-item-list a:hover{
  display: block;
  text-decoration: none;
  background: #f9f9f9;
  color: #373a47;
}

.bm-item-list a:first-child{
border-top: 1px solid #575a64;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  right: 0;
  top: 0;
}
/* Menu Style End */

.menu-burger-mobile{
  display: none;
}


.page__section {
  padding: 0;
  color: #4387ba;
  border-radius: 2px;
}

.page__section:nth-of-type(n+2){
  margin-top: 1em;
}

.breadcrumb_type5{
  --breadcrumbDividerSize: 20px;
}


.breadcrumb{
  --uiBreadcrumbTextColor: var(--breadcrumbTextColor);
  --uiBreadcrumbTextColorActive: var(--breadcrumbTextColorActive);
  
  --breadcrumbDividerColor: var(--uiBreadcrumbTextColor);
  --rLinkTextColor: var(--uiBreadcrumbTextColor);
  background: none;
  padding: 0px;
}

.breadcrumb__divider{
  color: #4d4d4d;
}

span.breadcrumb__point {
  color: #333333;
}


.r-list{
  --uirListPaddingLeft: var(--rListPaddingLeft, 0);
  --uirListMarginTop: var(--rListMarginTop, 0);
  --uirListMarginBottom: var(--rListMarginBottom, 0);
  --uirListListStyle: var(--rListListStyle, none);

  padding-left: var(--uirListPaddingLeft) !important;
  margin-top: var(--uirListMarginTop) !important;
  margin-bottom: var(--uirListMarginBottom) !important;
  list-style: var(--uirListListStyle) !important;
}

.r-link{
  --uirLinkDisplay: var(--rLinkDisplay, inline-flex);
  --uirLinkTextColor: var(--rLinkTextColor);
  --uirLinkTextDecoration: var(--rLinkTextDecoration, none);
  display: var(--uirLinkDisplay) !important;
  color: var(--uirLinkTextColor) !important;
  text-decoration: var(--uirLinkTextDecoration) !important;
}

/*
=====
COMPONENT
=====
*/

.breadcrumb{
  --uiBreadcrumbDividerColor: var(--breadcrumbDividerColor, inherit);
  --uiBreadcrumbDividerSize: var(--breadcrumbDividerSize, 16px);
  --uiBreadcrumbIndent:  var(--breadcrumbIndent, 10px);
}

.breadcrumb__list{
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__group{
  display: inline-flex;
  align-items: center;
}

.breadcrumb__group:nth-of-type(n+2){
  margin-left: var(--uiBreadcrumbIndent);
}

.breadcrumb__divider{
  margin-left: var(--uiBreadcrumbIndent);
  font-size: var(--uiBreadcrumbDividerSize);
}


.parent-payment-confirmation{
  width: 500px;
  margin: 0 auto; 
}
.parent-payment-confirmation .app-header{
  padding: 1em;
  font-size: 1em;
}
.parent-payment-confirmation .logo{
  margin-bottom: .5em;
  margin: 0 auto;
  max-width: 16em;
}
.parent-payment-confirmation .title{
  font-size: 1.7em;
  margin-top: .5em;
  text-align: center;
}

.parent-payment-confirmation .app-content{
  padding: 30px;
  background: white;
  border-radius: 0.3em;
  -webkit-box-shadow: 0 0 0.25em rgba(0,0,0,.3);
  box-shadow: 0 0 0.25em rgba(0,0,0,.3);
}
.parent-payment-confirmation .app-content .form-control{
  font-size: 15px;
  height: auto;
  padding: 10px;
  padding-bottom: 5px;
  -webkit-appearance: none;
}
.parent-payment-confirmation .app-content .control-label{
  margin: 0;
  font-size: 16px;
}
.parent-payment-confirmation .app-content .control-label .required{
  color: red;
}
.parent-payment-confirmation .app-content .konfirmasi_pembayaran{
  display: block;
  width: 100%;
  font-size: 19px;
  margin-top: 20px;
}

.finishconfirm-text{
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

span.notif-email {
  font-size: 11px;
  font-style: italic;
  margin-left: 3px;
  color: #878785;
}

.kupon-failed{
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  padding-bottom: 5px;
  display: none;
}

.kupon-success{
  font-size: 12px;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  padding-bottom: 5px;
  display: none;
}

.bonus-information-area{
    font-family: "Roboto", sans-serif !important;
    background: #fffbe2;
    padding: 20px;
    border-radius: 0.5em;
    border: 3px dashed #c40000;
    margin-bottom: 20px;
}
.bonus-information-area h3{
  font-size: 23px;
  font-weight: 700;
  display: block;
  margin-bottom: 20px;
}
.bonus-information-area p{
  font-size: 15px;
  color: #4e4e4e;
  font-family: "Roboto", sans-serif;
  line-height: 28px;
  margin-top: -6px;
}

.bonus-information-area ul{
    list-style: none;
    padding: 0;
    padding-left: 20px;
}

.bonus-information-area ul li{
    font-size: 17px;
    margin-top: 10px;
    margin-left: 10px;
}

.bonus-information-area ul li span.bonus-item-name{
	text-decoration: underline;
    font-weight: 600;
}
.bonus-information-area h2{
  font-size: 18px;
  color: #313131;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  margin-top: 17px;
}
.bonus-information-area h2 .total-bonus{
  text-decoration: underline;
}
.bonus-information-area h4{
  font-size: 12px;
  color: #4c4c4c;
  font-family: "Roboto", sans-serif;
  margin-top: 10px;
}

.warn-order-not-found{
  color: red;
  margin-top: 5px;
  display: block;
  font-style: italic;
  display: none;
}

.frame_no_pesanan input{
  display: block;
  width: 80%;
}

.frame_no_pesanan .cek_no_pesanan{
  color: white;
  float: right;
  margin-top: -38px;
  width: 18%;
  font-size: 16px;
}

.frame_no_pesanan .cek_no_pesanan:hover{
  color: white;
}

.react-datepicker-wrapper {
  display: block !important;
  margin-top: 5px !important;
}

.ke_kiri{
  display: block;
  width: 50px;
  float: left;
  margin-top: 120px;
  z-index: 1000;
  position: absolute;
  cursor: pointer;
  display: none;
}
.ke_kiri:hover{
  background: rgba(56, 58, 56, 0.3);
}

.ke_kanan{
  display: block;
  width: 50px;
  float: right;
  margin-top: -205px;
  z-index: 1000;
  position: relative;
  cursor: pointer;
  display: none;
}

.ke_kanan:hover{
  background: rgba(56, 58, 56, 0.3);
}

.select-payment-area{
  height: 70vh;
  width: 100%;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
}

.select-payment-area .inner{
  background: white;
  text-align: center;
  width: 500px;
  padding: 20px;
  margin: 0 auto;
  border-radius: 0.5em;
  box-shadow: 1px 1px 9px -6px #000;
}

.select-payment-area{
  height: 70vh;
  width: 100%;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
}

.select-payment-area .inner{
  background: white;
  text-align: center;
  width: 500px;
  padding: 20px;
  margin: 0 auto;
  border-radius: 0.5em;
  box-shadow: 1px 1px 9px -6px #000;
}

.select-payment-area .inner .IconLoading{
  width: 40px;
  margin-right: 10px;
  height: 40px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}

.select-payment-area .inner strong{
  font-size: 18px;
}

.metode-bayar {
    margin-top: 10px;
    text-align: center;
}

.p-m-bayar {
    font-size: 12px;
    color: #878787;
    font-style: italic;
    font-family: Roboto, sans-serif;
}

.img-bayar img {
    max-width: 100%;
    display: block;
    height: auto;
}

.tg-img {
  /* float: left;
  display: block; */
}

.tg-hd {
  /* float: left;
  margin-left: 20px; */
  width: 64%;
}

.tg-head1 {
  font-weight: bold;
  font-size: 18px;
  text-shadow: 1px 1px 3px #00000040;
}

.tg-desc {
  font-size: 13px;
  text-shadow: 1px 1px 3px #00000040;
}

.btn-tg-official {
  background: #ff2;
  box-shadow: 0 3px 0 #03525a;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 25px;
  border-radius: 4px;
  border: 1px solid #03525a;
  margin-top: 10px;
  transition: 0.1s;
}

.btn-tg-official:hover {
  box-shadow: none;
  margin-top: 12px;
}


.tooltip-ex { /* Container for our tooltip */
  margin-top: -59px;
  /* margin-left: -88px; */
  font-size: 12px;
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 3px !important;
 }
 
 .tooltip-ex-top {
  margin-top: -58px;
  margin-left: -88px;
  font-size: 12px;
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 3px !important;
 }
 
 .tooltip-ex-text {
  visibility: hidden;
  position: absolute;
  width: 270px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity .6s;
 }

 .tooltip-ex:hover + .tooltip-ex-text { /* Makes tooltip visible when hovered on */
  visibility: visible  !important;
  opacity: 1  !important;
 }

 .reverse-in-mobile{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: stretch;
 }

.btn-konfirmasi-bayar{
  background: #4CAF50;
  border: 1px solid #009006;
  color: #fff !important;
  padding: 10px 40px;
  border-radius: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
  display: inline-block;
  text-decoration: none !important;
}

.btn-konfirmasi-bayar.block-type{
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

@media (max-width: 575.98px) {

  .parent-payment-confirmation .title{
    font-size: 25px;
    margin-top: .5em;
    text-align: center;
  }

  .FrameThanks .price_important_info{
    display: inline-block;
    padding: .5em 1em;
    font-size: 12px;
    border-radius: 5px;
    background-color: #ffc107;
    margin-bottom: 20px;
    margin-right: -15px;
    margin-left: -15px;
  }

  .FrameThanks .price_important_info::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #ffc107;
    margin: -11px auto auto 208px;
  }

  .FrameThanks .payment_acount_detil h3{
    font-size: 21px;
  }
  
  .FrameThanks .payment_acount_detil h4{
      font-size: 18px;
  }

  .header-finish{
    display: none;
  }

  .w-100-mobile{
    width: 100% !important;
  }

  .cont-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
  }

  .reverse-in-mobile{
    flex-direction: column-reverse;
  }

  .app-footer-mobile{
    padding: 2em;
    text-align: center;
    padding-bottom: 5em;
  }

  .product-not-found{
    background: #fff;
    padding: 1em;
    border-radius: .5em;
    text-align: center;
    display: none;
    margin: 1em;
  }

  .product-not-found h2{
    font-size: 18px;
  }

  .product-not-found p{
    font-size: 13px;
    border-top: 1px solid #f1f1f1;
    margin-top: 10px;
    padding-top: 10px;
  }

  .img-bayar img {
    margin: 0 auto;
  }

  .modal-open .modal{
    padding: 0 !important;
    margin: 0 !important;
  }

  .modal-dialog{
    max-width: 100% !important;
    padding-top: 100px;
  }

  .title-and-price h4{
    text-align: left !important;
  }

  .parent-payment-confirmation{
    width: 90%;
    margin: 0 auto;
  }

  .title-and-price h2{
    text-align: left !important;
  }

  .bonus-information-area h3 {
      font-size: 18px  !important;
  }

  .bonus-information-area ul li {
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-left: -5px !important;
  }

  .bonus-information-area h2{
    font-size: 15px !important;
    margin-top: 28px !important;
  }

  .finishconfirm-text{
    font-size: 16px;
    margin-bottom: 10px;
    line-height: 24px;
  }

  .finishconfirm-text:last-child{
    margin-bottom: 0px;
  }

  .breadcrumb {
    font-size: 14px;
    display: block;
    width: 93%;
    overflow: scroll;
    margin: 0 auto;
    margin-top: -10px;
    margin-bottom: 5px;
  }
  .breadcrumb__list {
      display: flex;
      flex-wrap: unset;
      display: block;
      max-width: max-content;
      width: max-content;
  }

  .breadcrumb svg{
    margin-top: 0px !important;
  }

  .menu-burger-mobile{
    display: block;
  }

  .app-footer-mobile .footer-title img{
    width: 140px;
    position: relative;
    top: 0px;
    display: block;
    margin: 0 auto;
    /* margin-left: 0px; */
  }

  .container{
    padding: 5px !important;
  }

  .top-header-bar {
    margin-bottom: 0px;
    height: 60px;
  }

  .top-header-bar svg{
      margin-top: -18px;
  }

  .cont-area{
    margin-bottom: 10px;
  }

  .grid-product .child {
    width: auto;
    float: none;
    margin: 0;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24);
    flex: 1 1;
    flex: 1 1 30%;
    min-width: 125px;
  }

  .grid-product .child h3{
    height: 25px;
  }

  .list-product{
    margin-bottom: 10px !important;
    max-width: 100% !important;
    margin-right: 0% !important;
    margin-left: 0% !important;
  }

  .top-header-bar .right-content{
    display: none;
  }

  .fill-content{
    width: 100%;
  }

  .top-header-bar .left-content{
    margin:0 auto;
    float: left;
  }

  .buy-left-area {
    float: none;
    width: 100%;
    margin-right: 0%;
  }

  .buy-right-area {
    float: none;
    width: 100%;
  }

  .thumbnail {
    float: left;
    width: 30%;
    margin-right: 10px;
    margin: 0 auto;
}

.thumbnail img {
    width: 90px;
    height: 90px;
    object-fit: contain;
}

.title-and-price {
  float: left;
  width: 65%;
  margin-top: 0;
  text-align: center;
  margin-left: 15px;
}

.title-and-price h3 {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    text-align: left;
}

.action-button {
  display: table;
  float: none;
  margin-left: 35%;
}

.custom-field{
  float: none;
  /* margin-top: 2px;
  margin-bottom: 20px; */
  margin-top: 2px;
  margin-bottom: 20px;
  margin-left: -51%;
  text-align: left;
}

  .cont-header h2 {
    font-size: 17px;
    text-align: center;
    flex: 1;
    text-align: left;
    margin: 0;
  }

  .right-secure-img {
      /* float: none;
      margin-top: 0;
      margin: 0 auto;
      display: block;
      width: 182px;
      margin-bottom: 10px;
      margin-top: 10px; */

      float: none;
      display: block;
      width: auto;
      margin: 10px auto;
  }

  .right-secure-img img{
    width: 77px;
  }

  .action-button .frame-trash {
    display: table;
    width: 100%;
    margin-bottom: 10px;
    float: right;
    margin-right: -52px;
  }

  .frame-increase-decrease{
      float: left;
      margin-top: -39px;
  }

  .child-cont-form .control-label {
      font-size: 15px;
  }

  .child-cont-form .sub-child-cont-form {
      width: 100%;
      float: none;
      margin-right: 0%;
      margin-bottom: 20px;
  }

  .frame-grid-product{
    width: 100%;
    overflow: auto;
  }

  .header-finish img {
      width: 100%;
  }

  .grid-product {
    /* display: table; */
    /* width: max-content; */
    overflow: auto;
    display: flex;
    width: auto;
    gap: 15px;
    flex-wrap: wrap;
  }

  .frame-breadcustom{
    margin-left: 0px;
  }

  .box-yt{
      width: 100%;
      float: none;
      margin: 0;
      margin-bottom: 20px;
  }

  .box-yt img{
      width: 100%;
  }

  .header-web {
      height: 70px;
      background: #ffffff;
      width: 100%;
      border-bottom: 1px solid #ddd;
  }
  .logosss img {
      height: 40px;
      margin-top: 16px;
  }
  .tab-selector-payment .content-payment-area .frame-secure-label{
      max-width: 90%;
  }
  .secure-right{
  width: 70%;
  text-align: left;
  float: left;
  }
  .tab-selector-payment .content-payment-area .table-list-product-payment{
      width: 90%;
  }
  .tab-selector-payment .content-payment-area .next-payment{
      width: 90%;
  }
  .header-web{
      width: 100%;
  }
  .header-container{
      width: 100%;
  }
  .logosss {
    float: none;
    margin: 0 auto;
    display: block;
    width: 100%;
    margin-left: 0;
  }

  .logosss svg {
      height: 67px;
      width: 67px;
      margin-top: 0;
      margin: 0 auto;
      padding: 0;
      display: block;
  }
  .menusss{
      float: none;
      background: white;
      display: block;
      position: absolute;
      z-index: 1000;
      width: 100%;
      height: auto;
      display: none;
      padding-bottom: 0px;
      box-shadow: 0px 10px 10px -10px #52525259;
      margin-top: -8px;
  }
  .menusss ul{
      padding-top: 10px;
  }
  .menusss ul li{
      float: none;
      padding: 5px 15px 5px 15px;
      text-align: center;
      border-bottom: 1px solid #e2e2e2;
      margin-bottom: 10px;
      padding-bottom: 10px;
      margin-left: 15px;
      margin-right: 15px;
  }
  .menusss ul li:last-child{
      border-bottom: 0px;
  }
  .menusss_mobile_trigger{
      display: block;
      position: absolute;
      right: 25px;
      top: 20px;
      font-size: 30px;
  }
  .menusss_mobile_trigger a{
      color: #892b85;
  }
  .menusss_mobile_trigger a:hover{
      color: #b72781;
  }
  .menusss ul li a{
      font-size: 15px;
  }
  #pswd_info {
      position: absolute;
      bottom: -155px;
      bottom: -115px\9;
      right: 226px;
      width: 210px;
      padding: 2px;
      background: #fefefe;
      font-size: .875em;
      border-radius: 5px;
      box-shadow: 0 1px 3px #ccc;
      border: 1px solid #ddd;
      z-index: 1000;
      padding-top: 15px;
      display: none;
  }
  .secure_checkout_img{
    width: 100% !important;
  }
  .FrameThanks .payment_acount_detil .child-bank{
      display: block;
      width: 100%;
      padding: 0px;
  }
  .menusss ul li a{
      font-size: 15px;
  }
  .FrameThanks .payment_acount_detil{
    width: 100%;
    border: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
  .FrameThanks .payment_acount_detil .child-bank{
      border:none !important;
  }
  .FrameThanks .caption_3 p{
    width: 100%;
    line-height: 25px;
    font-size: 15px;
  }
  .breadcustom{
  width: 100%;
  display: block;
  overflow: hidden;
  }
  ul.breadcrumb{
      width: 100%;
  }
  .prd-tbl{
      display: block;
  }
  .prd-tbl .con-left{
      text-align: center;
  }
  .prd-tbl .con-right{
      text-align: center;
  }
  .tab-selector-payment{
      width: 100%;
      padding: 0 !important;
  }
  .tab-selector-payment .payment-area .header-payment-area {
  padding: 20px !important;
  }
  .tab-selector-payment .payment-area .header-payment-area img{
      float: right;
      height: 28px;
      margin-top: -24px;
  }
  .secure-left{
  float: left;
  text-align: center;
  margin-left: 16px;
  }
  .tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon{
      margin-top: 20px;
      width: 100%;
      border-radius: 0.4em;
      border: 1px solid #ccc;
      padding: 3px;
  }
  .tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon input{
      border: none;
      padding: 5px;
      text-align: left;
      font-size: 13px;
      height: 32px;
      width: 73%;
  }
  .tab-selector-payment .payment-area .header-payment-area img{
      float: right;
      height: 28px;
      margin-top: 2px;
      margin-left: 19px;
      margin-top: -22px;
  }

  .FrameListProducts{
      display: block;
  }
  .FrameListProducts .child{
      max-width: 90%;
      margin: 0 auto !important;
  }
  .pswd_info_in_member{
      right: 59px !important;
      bottom: -153px !important;
  }
  ul.breadcrumb li:last-child{
      width: 20%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .tab-selector-payment .content-payment-area .table-list-product-payment .frame-coupon a {
      float: right;
  }
  img.paypal-img {
      top: 21px;
      position: relative;
  }
  .discount_notif {
      position: absolute;
      color: white;
      padding: 8px;
      border-radius: 5px;
      margin-left: -4px;
      margin-top: 11px;
      font-size: 11px;
      width: 65%;
      text-align: center;
  }
  .img-not-found{
      text-align: center;
      padding: 50px;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 20px;
  }
  .tab-selector-payment .payment-area .header-payment-area h3{
      margin-left: 23px;
      margin-right: 0px !important;
  }
  .notfon h3 {
    text-align: center !important;
    padding-bottom: 20px !important;
    font-weight: 500 !important;
    padding-top: 0px !important;
    font-size: 17px !important;
    margin-top: -10px !important;
    line-height: 28px !important;
}
.notfon h2 {
    text-align: center !important;
    padding-bottom: 20px !important;
    font-weight: bold !important;
    margin-top: 10px !important;
    font-size: 20px !important;
}
}

.list-product{
  margin-bottom: 30px;
  background: white;
  padding: 20px;
  border-radius: 0.3em;
  box-shadow: 1px 1px 5px -3px #ccc;
  max-width: 30.3%;
  margin-right: 1.5%;
  margin-left: 1.5%;
}
.list-product:hover{
  box-shadow: 1px 1px 15px -3px #ccc;
}
.list-product h2{
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: bold;
  display: block;
  height: 41px;
  vertical-align: middle;
}
.list-product a{
  text-align: center;
  display: block;
  background: #5cb85c;
  color: white;
  padding: 7px;
  border-radius: 0.5em;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 10px;
}
.list-product a:hover{
  text-decoration: none;
  background: #ed1a24;
}

.list-product a.remove{
  background: #d31620;
}

.list-product img{
    /* width: 200px; */
    text-align: center;
    margin: 0 auto;
    display: block;
    /* height: 200px; */
    height: 244px;
}
.list-product .elementor-widget-container{

}
.list-product .elementor-widget-container strike{
  
}

.cont-form-flex{
  margin-top: -20px;
  box-shadow: 1px 2px 3px 0 #6161612b;
  background: white;
  padding: 25px;
}
.cont-form-flex .header-finish{

}
.cont-form-flex .header-finish img{
  width: 125px
}

.greetings-header{
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.greetings-text{
  text-align: center;
  font-size: 15px;
  font-weight: 300;
}

.other-products-buttons{
  background: #ffa82b;
  box-shadow: 0 3px 0 #03525a;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 29px;
  border-radius: 4px;
  border: 1px solid #03525a;
  margin-top: 10px;
  transition: 0.1s;
  margin: 0 auto;
  display: block;
  width: 200px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 10px;
  color: black !important;
  text-decoration: none !important;
}
.other-products-buttons:hover{
  box-shadow: none;
  margin-top: 32px;
}