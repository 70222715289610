$green: #56c080;

.toggle {
	cursor: pointer;
	display: inline-block;
}
.toggle-switch {
	display: inline-block;
	background: #ccc;
	border-radius: 16px;
	width: 58px;
	height: 32px;
	position: relative;
	vertical-align: middle;
	transition: background 0.25s;
	&:before,
	&:after {
		content: "";
	}
	&:before {
		display: block;
		background: linear-gradient(to bottom, #fff 0%,#eee 100%);
		border-radius: 50%;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.25);
		width: 24px;
		height: 24px;
		position: absolute;
		top: 4px;
		left: 4px;
		transition: left 0.25s;
	}
	.toggle:hover &:before {
		background: linear-gradient(to bottom, #fff 0%,#fff 100%);
		box-shadow: 0 0 0 1px rgba(0,0,0,0.5);
	}
	.toggle-checkbox:checked + & {
		background: $green;
		&:before {
			left: 30px;
		}
	}
}
.toggle-checkbox {
	position: absolute;
	visibility: hidden;
}
.toggle-label {
	margin-left: 5px;
	position: relative;
	top: 2px;
}

@media (max-width: 575.98px) {
	.toggle-switch {
		display: inline-block;
		background: #ccc;
		border-radius: 16px;
		width: 49px;
		height: 20px;
		position: relative;
		vertical-align: middle;
		transition: background 0.25s;
	}
	.toggle-switch:before {
		width: 14px;
		height: 14px;
	}
	.toggle-label {
		margin-left: 5px;
		position: static;
		top: 0;
		font-size: 12px;
	}
	.toggle {
		border-bottom: 1px solid #e1e1e1;
		padding-bottom: 10px;
	}
}